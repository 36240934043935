@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-Light-web.15f36557.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-LightItalic-web.805232bf.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-Regular-web.6e61acb1.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-RegularItalic-web.34a65554.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-Medium-web.31d2f6b6.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-Bold-web.5c78c6a0.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: TWK Everett;
  src: url("TWKEverett-BoldItalic-web.35da1541.woff2") format("woff2");
  font-weight: bold;
  font-style: italic;
}

:root {
  --color-body: #edeae5;
  --color-banner: #c6c8ff;
  --color-header: #000;
  --color-header-invert: #fff;
}

body {
  background: var(--color-body);
  margin: 0;
  font-family: TWK Everett, Arial, sans-serif;
}

body.scrolled {
  --color-header: #fff;
  --color-header-invert: #000;
}

.button {
  height: 32px;
  color: #000;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 16px;
  padding: 0 28px;
  font-size: 16px;
  font-weight: 300;
  line-height: 32px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
}

.top {
  z-index: 20;
  color: var(--color-header);
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  margin: 30px;
  display: grid;
  position: sticky;
  top: 30px;
}

.top a.logo {
  color: currentColor;
  background: none;
  border: none;
}

.top a.logo:hover {
  cursor: pointer;
}

@media screen and (min-width: 600px) {
  .top a.logo {
    grid-column-start: 2;
  }
}

.top a.logo svg {
  width: 150px;
  height: auto;
  transition: all .2s;
  display: block;
}

@media screen and (min-width: 600px) {
  .top a.logo svg {
    width: 167px;
  }
}

.top .button {
  color: currentColor;
  border: 1px solid;
  grid-column-start: 3;
  margin-left: auto;
  display: block;
}

.top .button:hover {
  background: var(--color-header);
  border-color: var(--color-header);
  color: var(--color-header-invert);
}

.marquee {
  background: var(--color-banner);
  color: #fff;
  letter-spacing: .9px;
  height: 42px;
  font-size: 18px;
  font-weight: 300;
  line-height: 42px;
}

header {
  z-index: 10;
  background: var(--color-body);
  position: relative;
}

header h1 {
  height: 50vh;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 38px;
  font-weight: 300;
  line-height: 1.09;
  display: flex;
}

@media screen and (min-width: 480px) {
  header h1 {
    font-size: 52px;
  }
}

@media screen and (min-width: 600px) {
  header h1 {
    font-size: 70px;
  }
}

main.index .item {
  height: 100vh;
  color: #fff;
  background-position: center;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

main.index .item.item--1 {
  background-image: url("evnw-banner-mobile-rm_global.245bb1fc.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--1 {
    background-image: url("evnw-banner-rm_global.9e7206ed.jpg");
  }
}

main.index .item.item--1 .item__copy {
  position: relative;
}

main.index .item.item--1.reached .item__copy {
  position: fixed;
}

main.index .item.item--1:not(.reached) .item__copy {
  opacity: 1;
  position: relative;
  transform: translate3d(0, 0, 0);
}

main.index .item.item--3 {
  background-image: url("evnw-banner-mobile-pvh_gqm.3558b4b6.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--3 {
    background-image: url("evnw-banner-pvh_gqm.26bc9e15.png");
  }
}

main.index .item.item--4 {
  background-image: url("evnw-banner-mobile-rm-frankfurter_brett.8a315aa6.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--4 {
    background-image: url("evnw-banner-frankfurter_brett.0d4ee2e4.jpg");
  }
}

main.index .item.item--5 {
  background-image: url("evnw-banner-mobile-rm-frankfurter_sparkasse.8b03dd4d.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--5 {
    background-image: url("evnw-banner-sparkasse.df601189.jpg");
  }
}

main.index .item.item--6 {
  background-image: url("evnw-banner-mobile-rm-deutsche_bank.ae3b476f.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--6 {
    background-image: url("evnw-banner-deutsche_bank.1dbec55a.jpg");
  }
}

main.index .item.item--7 {
  background-image: url("evnw-banner-mobile-swissport.6474c56e.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--7 {
    background-image: url("evnw-banner-swissport.99ff2e28.jpg");
  }
}

main.index .item.item--8 {
  background-image: url("evnw-banner-mobile-rm-lh_technik.267a5ce2.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--8 {
    background-image: url("evnw-banner-lufthansa_technik.ab1b2c70.png");
  }
}

main.index .item.item--9 {
  background-image: url("evnw-banner-mobile-rm-sunexpress.189ced1f.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--9 {
    background-image: url("evnw-banner-sunexpress.2081c9d2.jpg");
  }
}

main.index .item.item--10 {
  background-image: url("evnw-banner-mobile-rm-bt_character.7cc7cb8a.jpg");
}

@media screen and (min-width: 600px) {
  main.index .item.item--10 {
    background-image: url("evnw-banner-bethmann_character.7acf35dc.jpg");
  }
}

main.index .item.item--10.reached .item__copy {
  opacity: 1;
  position: relative;
  transform: translate3d(0, 0, 0);
}

main.index .item .item__copy {
  z-index: 5;
  height: 100vh;
  opacity: 0;
  transform: translate3d(0, var(--transform), 0);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  transition: all .25s;
  display: flex;
  position: fixed;
  top: 0;
}

main.index .item.active .item__copy {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

main.index .item h2 {
  letter-spacing: .5px;
  text-transform: uppercase;
  text-align: center;
  margin: 0 0 10px;
  font-size: 32px;
  font-weight: normal;
  line-height: 1.16;
}

@media screen and (min-width: 600px) {
  main.index .item h2 {
    font-size: 50px;
  }
}

main.index .item h3 {
  letter-spacing: .15px;
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.2;
  position: relative;
}

@media screen and (min-width: 600px) {
  main.index .item h3 {
    font-size: 30px;
  }
}

main.index .item video {
  object-fit: cover;
  aspect-ratio: 16 / 9;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  inset: 0;
}

main.imprint {
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 30px;
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

@media screen and (min-width: 600px) {
  main.imprint {
    padding: 0 40px;
  }
}

main.imprint h1, main.imprint .h1 {
  margin: 200px 0 80px;
  font-size: 38px;
  font-weight: 300;
  line-height: 1.09;
}

@media screen and (min-width: 480px) {
  :is(main.imprint h1, main.imprint .h1) {
    font-size: 52px;
  }
}

:is(main.imprint h1, main.imprint .h1) a {
  vertical-align: 6px;
  width: 32px;
  text-align: center;
  padding: 0;
}

@media screen and (min-width: 480px) {
  :is(main.imprint h1, main.imprint .h1) a {
    vertical-align: 10px;
  }
}

main.imprint h2 {
  margin: 40px 0 20px;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.09;
}

main.imprint strong {
  font-weight: 500;
}

main.imprint p {
  margin: 0 0 25px;
}

main.imprint .imprint__copy a {
  color: currentColor;
  text-transform: uppercase;
}

@media screen and (min-width: 600px) {
  main.imprint .imprint__copy {
    grid-column-gap: 40px;
    grid-template-columns: 1fr 1fr;
    display: grid;
  }
}

@media screen and (min-width: 900px) {
  main.imprint .imprint__copy {
    grid-column-gap: 100px;
  }
}

main.imprint .terms__copy a {
  color: currentColor;
}

footer {
  z-index: 10;
  position: relative;
}

footer .footer__copy {
  grid-gap: 40px;
  grid-template-columns: 1fr;
  align-items: flex-end;
  padding: 60px 40px;
  display: grid;
}

@media screen and (min-width: 480px) {
  footer .footer__copy {
    grid-template-columns: 1fr auto;
  }
}

footer .footer__claim {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
}

footer .footer__claim strong {
  font-weight: 500;
}

footer .footer__address {
  font-size: 18px;
  font-weight: 300;
  line-height: 28px;
}

footer .footer__address a {
  color: #000;
}

footer .footer__address a:first-of-type {
  text-decoration: none;
}

footer .footer__address a:first-of-type:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.6d0fbd2e.css.map */
