@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-Light-web.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-LightItalic-web.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-Regular-web.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-RegularItalic-web.woff2') format('woff2');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-Medium-web.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-Bold-web.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'TWK Everett';
  src: url('./fonts/TWKEverett-BoldItalic-web.woff2') format('woff2');
  font-weight: bold;
  font-style: italic;
}

:root {
  --color-body: #edeae5;
  --color-banner: #c6c8ff;
  --color-header: #000;
  --color-header-invert: #fff;
}

body {
  margin: 0;
  font-family: 'TWK Everett', Arial, sans-serif;
  background: var(--color-body);
}

body.scrolled {
  --color-header: #fff;
  --color-header-invert: #000;
}

.button {
  font-weight: 300;
  font-size: 16px;
  height: 32px;
  line-height: 32px;
  border: 1px solid #000;
  color: #000;
  padding: 0 28px;
  border-radius: 16px;
  box-sizing: border-box;
  transition: 200ms;
  text-decoration: none;
  display: inline-block;
}

.top {
  margin: 30px;
  position: sticky;
  z-index: 20;
  top: 30px;
  color: var(--color-header);
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  & a.logo {
    background: none;
    border: none;
    color: currentColor;

    &:hover {
      cursor: pointer;
    }

    @media screen and (min-width: 600px) {
      grid-column-start: 2;
    }

    & svg {
      display: block;
      width: 150px;
      height: auto;
      transition: 200ms;

      @media screen and (min-width: 600px) {
        width: 167px;
      }
    }
  }

  & .button {
    margin-left: auto;
    border: 1px solid currentColor;
    color: currentColor;
    grid-column-start: 3;
    display: block;

    &:hover {
      background: var(--color-header);
      border-color: var(--color-header);
      color: var(--color-header-invert);
    }
  }
}

.marquee {
  background: var(--color-banner);
  color: #fff;
  font-size: 18px;
  letter-spacing: 0.9px;
  font-weight: 300;
  height: 42px;
  line-height: 42px;
}

header {
  position: relative;
  z-index: 10;
  background: var(--color-body);

  & h1 {
    height: 50vh;
    text-align: center;
    font-size: 38px;
    line-height: 1.09;
    font-weight: 300;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 480px) {
      font-size: 52px;
    }

    @media screen and (min-width: 600px) {
      font-size: 70px;
    }
  }
}

main.index {
  & .item {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    background-size: cover;
    background-position: center center;

    &.item--1 {
      background-image: url('assets/evnw-banner-mobile-rm_global.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-rm_global.jpg');
      }

      & .item__copy {
        position: relative;
      }

      &.reached {
        & .item__copy {
          position: fixed;
        }
      }

      &:not(.reached) {
        & .item__copy {
          position: relative;
          opacity: 1;
          transform: translate3d(0, 0px, 0);
        }
      }
    }

    &.item--3 {
      background-image: url('assets/evnw-banner-mobile-pvh_gqm.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-pvh_gqm.png');
      }
    }

    &.item--4 {
      background-image: url('assets/evnw-banner-mobile-rm-frankfurter_brett.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-frankfurter_brett.jpg');
      }
    }

    &.item--5 {
      background-image: url('assets/evnw-banner-mobile-rm-frankfurter_sparkasse.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-sparkasse.jpg');
      }
    }

    &.item--6 {
      background-image: url('assets/evnw-banner-mobile-rm-deutsche_bank.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-deutsche_bank.jpg');
      }
    }

    &.item--7 {
      background-image: url('assets/evnw-banner-mobile-swissport.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-swissport.jpg');
      }
    }

    &.item--8 {
      background-image: url('assets/evnw-banner-mobile-rm-lh_technik.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-lufthansa_technik.png');
      }
    }

    &.item--9 {
      background-image: url('assets/evnw-banner-mobile-rm-sunexpress.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-sunexpress.jpg');
      }
    }

    &.item--10 {
      background-image: url('assets/evnw-banner-mobile-rm-bt_character.jpg');

      @media screen and (min-width: 600px) {
        background-image: url('assets/evnw-banner-bethmann_character.jpg');
      }

      &.reached {
        & .item__copy {
          position: relative;
          opacity: 1;
          transform: translate3d(0, 0px, 0);
        }
      }
    }

    & .item__copy {
      top: 0;
      position: fixed;
      z-index: 5;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      opacity: 0;
      transform: translate3d(0, var(--transform), 0);
      transition: 250ms;
      padding: 0 30px;
    }

    &.active {
      & .item__copy {
        opacity: 1;
        transform: translate3d(0, 0px, 0);
      }
    }

    & h2 {
      font-size: 32px;
      line-height: 1.16;
      font-weight: normal;
      margin: 0 0 10px 0;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      text-align: center;

      @media screen and (min-width: 600px) {
        font-size: 50px;
      }
    }

    & h3 {
      font-size: 24px;
      line-height: 1.2;
      font-weight: 300;
      margin: 0;
      letter-spacing: 0.15px;
      position: relative;
      text-align: center;

      @media screen and (min-width: 600px) {
        font-size: 30px;
      }
    }

    & video {
      display: block;
      object-fit: cover;
      aspect-ratio: 16 / 9;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }
  }
}

main.imprint {
  max-width: 1024px;
  padding: 0 30px;
  margin: 0 auto;
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;

  @media screen and (min-width: 600px) {
    padding: 0 40px;
  }

  & h1,
  .h1 {
    margin: 200px 0 80px 0;
    font-size: 38px;
    line-height: 1.09;
    font-weight: 300;

    @media screen and (min-width: 480px) {
      font-size: 52px;
    }

    & a {
      vertical-align: 6px;
      width: 32px;
      text-align: center;
      padding: 0;

      @media screen and (min-width: 480px) {
        vertical-align: 10px;
      }
    }
  }

  & h2 {
    margin: 40px 0 20px 0;
    font-size: 28px;
    line-height: 1.09;
    font-weight: 300;
  }

  & strong {
    font-weight: 500;
  }

  & p {
    margin: 0 0 25px 0;
  }

  .imprint__copy {
    & a {
      color: currentColor;
      text-transform: uppercase;
    }

    @media screen and (min-width: 600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
    }

    @media screen and (min-width: 900px) {
      grid-column-gap: 100px;
    }
  }

  .terms__copy {
    & a {
      color: currentColor;
    }
  }
}

footer {
  position: relative;
  z-index: 10;

  & .footer__copy {
    padding: 60px 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    align-items: flex-end;

    @media screen and (min-width: 480px) {
      grid-template-columns: 1fr auto;
    }
  }

  & .footer__claim {
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;

    & strong {
      font-weight: 500;
    }
  }

  & .footer__address {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;

    & a {
      color: #000;

      &:first-of-type {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
